import React from 'react';

import Layout from '../layouts/Layout';
import Seo from '../components/Seo/Seo';

const AccountSettings = () => {
  return (
    <Layout section={'payment'} bannerTitle="Account">
      <section className="section homepage-content">
        <div className="container">
          <>
            <div className="columns">
              <div className="column">
                <div className="content">
                  <div className="columns">
                    <div className="column is-3">
                      <div className="sidebar-menu">
                        <a href="#">Edit Profile</a>
                        <a href="#">Bookings</a>
                        <a href="#">Payment</a>
                        <a className="logout" href="#">
                          Log out
                        </a>
                      </div>
                    </div>
                    <div className="column">
                      <div className="profile-content-wrapper">
                        <h3>Profile</h3>
                        <div className="columns">
                          <div className="column is-half">
                            <div className="field">
                              <p className="control">
                                <input
                                  className="input email"
                                  type="email"
                                  placeholder="Email"
                                />
                              </p>
                            </div>
                          </div>
                          <div className="column is-half">
                            <div className="field">
                              <p className="control">
                                <input
                                  className="input name"
                                  type="text"
                                  placeholder="First Name"
                                />
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="columns">
                          <div className="column is-half">
                            <div className="field">
                              <p className="control">
                                <input
                                  className="input name"
                                  type="text"
                                  placeholder="Last Name"
                                />
                              </p>
                            </div>
                          </div>
                        </div>
                        <h3>Password</h3>
                        <div className="columns">
                          <div className="column is-half">
                            <div className="field">
                              <p className="control">
                                <input
                                  className="input password"
                                  type="text"
                                  placeholder="Old Password"
                                />
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="columns">
                          <div className="column is-half">
                            <div className="field">
                              <p className="control">
                                <input
                                  className="input password"
                                  type="text"
                                  placeholder="New Password"
                                />
                              </p>
                            </div>
                          </div>
                          <div className="column is-half">
                            <div className="field">
                              <p className="control">
                                <input
                                  className="input password"
                                  type="text"
                                  placeholder="Confirm Password"
                                />
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="columns">
                          <div className="column is-half">
                            <a className="button save-changes-btn" href="#">
                              {' '}
                              Save Changes{' '}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        </div>
      </section>
    </Layout>
  );
};

export default AccountSettings;
export const Head = ({ location }) => (
  <Seo
    pathname={location.pathname}
  />
);